<template>
  <CChartBar
    :datasets="defaultDatasets"
    :options="computedOptions"
    style="height:300px"
     :labels="kecamatan"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
export default {
  name: 'ChartLuasLahanKecamatan',
  components: { CChartBar },
  data() {
    return {
      kecamatan: [],
      tidak_dipertahankan: [],
      dipertahankan: [],
      cadangan: [],
      irigasi_macro: [],
      total: [],
      colors: []
    }
  },
  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
  },
  computed: {
    defaultDatasets () {
      return this.irigasi_macro
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  },
  mounted() {
    this.$store.dispatch("lp2b/totalLuasKecamatan").then((resp) => {

      resp.tidak_dipertahankan.forEach(element => {
        this.tidak_dipertahankan.push(element.total)
        this.kecamatan.push(element.kecamatan)
      });

      resp.dipertahankan.forEach(element => {
        this.dipertahankan.push(element.total)
      });

      resp.cadangan.forEach(element => {
        this.cadangan.push(element.total)
      });

      this.irigasi_macro = [
        {
          label: 'Lahan Tidak Dipertahankan',
          backgroundColor: this.getRandomColor(),
          data: this.tidak_dipertahankan
        },
        {
          label: 'Lahan Dipertahankan',
          backgroundColor: this.getRandomColor(),
          data: this.dipertahankan
        },
         {
          label: 'Lahan Cadangan',
          backgroundColor: this.getRandomColor(),
          data: this.cadangan
        },
      ]

      console.log(this.irigasi_macro, "wkwkwkwk")

    }).catch(e => {
      this.$toast.error(e);
    });
  },
}
</script>
